import {
  faDraftingCompass,
  faTruck,
  faWallet,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import { ServicesContentQuery } from "../../graphql-types";
import { Intro } from "../components/Intro";
import { Layout } from "../components/Layout";
import { ServicesSection } from "../components/services/ServicesSection";

export const query = graphql`
  query ServicesContent {
    datoCmsService {
      progettazione
      consegne
      serviziComplementari
      pagamenti
    }
  }
`;

interface ServicesProps {
  data: ServicesContentQuery;
}

const ServicesPage: React.FC<ServicesProps> = props => {
  return (
    <Layout>
      <Helmet>
        <title>
          I nostri servizi - progettazione arredamenti componibili - preventivi
          - consegne - pagamenti rateizzati
        </title>
      </Helmet>
      <Intro title="Servizi"></Intro>
      <ServicesSection
        title="Progettazione"
        icon={faDraftingCompass}
        direction="right"
        content={props.data.datoCmsService!.progettazione!}
      ></ServicesSection>
      <ServicesSection
        title="Consegne"
        icon={faTruck}
        direction="left"
        content={props.data.datoCmsService!.consegne!}
        withBackground
      ></ServicesSection>
      <ServicesSection
        title="Servizi Complementari"
        icon={faWrench}
        direction="right"
        content={props.data.datoCmsService!.serviziComplementari!}
      ></ServicesSection>
      <ServicesSection
        title="Pagamenti"
        icon={faWallet}
        direction="left"
        content={props.data.datoCmsService!.pagamenti!}
        withBackground
      ></ServicesSection>
    </Layout>
  );
};

export default ServicesPage;
