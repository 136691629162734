import styled from "styled-components";
import { mdBreakpoint } from "../../shared/constants";

export type ServicesDirection = "left" | "right";

interface ServiceContainerProps {
  direction: ServicesDirection;
}

export const ServiceContainer = styled.div<ServiceContainerProps>`
  div:first-child {
    text-align: center;
    padding-bottom: 1rem;
  }

  @media (min-width: ${mdBreakpoint}px) {
    display: flex;
    align-items: center;

    div:first-child {
      padding: 0 2rem;
      order: ${props => (props.direction === "left" ? 0 : 1)};
    }
  }
`;
