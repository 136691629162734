import { faCircle, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Section } from "../section/Section";
import { SectionHeader } from "../section/SectionHeader";
import { ServiceContainer, ServicesDirection } from "./ServicesContainer";

interface ServicesSectionProps {
  title: string;
  content: string;
  icon: IconDefinition;
  direction: ServicesDirection;
  withBackground?: boolean;
}

export const ServicesSection: React.FC<ServicesSectionProps> = props => {
  return (
    <Section withBackground={props.withBackground}>
      <ServiceContainer direction={props.direction}>
        <div>
          <span className="fa-layers fa-fw fa-10x">
            <FontAwesomeIcon icon={faCircle} color="#13a456" />
            <FontAwesomeIcon
              icon={props.icon}
              color="#ffffff"
              transform="shrink-8"
            ></FontAwesomeIcon>
          </span>
        </div>
        <div>
          <SectionHeader>{props.title}</SectionHeader>
          <div
            dangerouslySetInnerHTML={{
              __html: props.content,
            }}
          ></div>
        </div>
      </ServiceContainer>
    </Section>
  );
};
